import React, { useState, useEffect } from "react"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import $ from "jquery"

// Temporary Data
import propertyDetails from "../data/property-details.json"

// Components
import Layout from "../components/layout"
import BreadCrumbs from "../components/BreadCrumbs/StaticBreadcrumb"
import PropertyOverview from "../layout/PropertyDetails/PropertyOverview"
import Description from "../layout/PropertyDetails/Description"
import BroadbandDetails from "../layout/PropertyDetails/BroadbandDetails"
import LocRatingMap from "../components/LocratingMap/locrating-map-details"
import LocationDetails from "../layout/PropertyDetails/LocationDetails"
import AreaGuide from "../layout/PropertyDetails/AreaGuide"
import SimilarProperties from "../layout/PropertyDetails/SimilarProperties"
import BrandLogo from "../images/resiland-logo.svg";

import {GetAPIData, Property_API} from "../components/common/agentAPI"


const PropertyDetailsTemplate = (props) => {
    const [ loading, setLoading ] = useState(true);
    const [ property_details, setGetDetails ] = useState(false);
    // 
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let myid_filt = pageurl.split('?');
    let mypageurl = myid_filt[0];
    let property_slug = mypageurl.split('-');
    let id = property_slug[property_slug.length - 1];
    id = id.replace("/", "");

    // const { loading, error, data: property_details } = useQuery(PROPERTY_DETAILS, {
    //     variables: { id: id }
    // });

    const scrollToDiv = (div_id) => {
        $('html, body').animate({
            scrollTop: $("#"+div_id).offset().top - 250
        }, 'smooth');
    }

    useEffect(()=>{
        var api_link = Property_API+id+`?request_from=website`
        GetAPIData(api_link).then(async apiRes => {
            if(apiRes?.success === true){
                setGetDetails(apiRes)
                setLoading(false)
            }
        });
    },[id])

    // Loading logo 
    if (loading) return (
        <section className={"loader-wrapper"}>
            <div id="loader-wrapper">
                <div id="loader" class="new-loader">
                    <img className="logo-white loader-logo" src={BrandLogo} alt="logo"/>
                </div>
            </div>
        </section>
    );
    // Loading logo
    // 
    
    //console.log("property_details", property_details);

    // Get Map Details
    let mapItems = [];
    let mapItem = {};
    mapItem['lat']  = property_details?.latitude      
    mapItem['lng']  = property_details?.longitude
    mapItem['id']  = property_details?.id
    const mapMarkerImageShowFlag = 0;
    mapItems.push( mapItem );
    // Get Map Details

    var link = "/residential/property/for-sale/in-london/", lable_name = "Residential For Sale";

    if(property_details?.department === "residential" && property_details?.search_type === "lettings"){
        lable_name = "Residential To Rent";
        link = "/residential/property/to-rent/in-london/";
    }

    if(property_details?.department === "commercial" && property_details?.search_type === "sales"){
        lable_name = "Commercial For Sale";
        link = "/commercial/property/for-sale/in-london/";
    }

    if(property_details?.department === "commercial" && property_details?.search_type === "lettings"){
        lable_name = "Commercial To Rent";
        link = "/commercial/property/to-rent/in-london/";
    }
    


    return (
        <React.Fragment>
            <Layout template="property_details">
                {property_details && <>
                    <BreadCrumbs
                        breadCrumbsData={ 
                            [{
                                name: 'Home',
                                link: '/'
                            },   
                            {
                                name: lable_name,
                                link: link
                            },                         
                            {
                                name: property_details?.display_address ? property_details?.display_address : property_details?.title,
                                // link: '#'
                            }]
                        }
                    />
                    <PropertyOverview propertyData={property_details} scrollToDiv={scrollToDiv} />
                    <Description propertyData={property_details} />
                    <BroadbandDetails />
                    <div className="property-details-landing-map" id="property-details-map" data-item="property-details-map">
                        <LocRatingMap data={mapItems} />
                    </div>
                    {/* <LocationDetails /> */}
                    {property_details?.parent_building?.town && <AreaGuide area={property_details?.parent_building?.town}/>}
                    <SimilarProperties similarPropertiesData={ propertyDetails.SimilarProperties } />
                </>
                }
            </Layout>
        </React.Fragment>
    )
}

export default PropertyDetailsTemplate